import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { getBuildIdQuery, IActionBar, NcgAppComponentInterface } from '@ncg/data';
import { BloomreachService, LazyContentAliases, MetaService, ScrollService, SettingsService } from '@ncg/ui';
import { TranslateService } from '@ngx-translate/core';
import { Subject, take } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ncg-root',
    template: `
        <div class="site__wrapper">
            <ngx-json-ld [json]="schema" *ngIf="schema"></ngx-json-ld>
            <div class="content__wrapper">
                <ng-template #vcModelPageNav></ng-template>
                <ncg-header></ncg-header>
                <router-outlet></router-outlet>
            </div>
            <ng-container #vcFooter></ng-container>
            <ng-container *ngIf="actionBarData">
                <ng-template ncgLazyComponentLoader [data]="actionBarData" [alias]="actionBarAlias"></ng-template>
            </ng-container>
            <ncg-page-transition></ncg-page-transition>
            <ng-template ncgLazyComponentLoader [alias]="previewOverlayAlias"></ng-template>
        </div>
        <ncg-up></ncg-up>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy, NcgAppComponentInterface {
    previewOverlayAlias = LazyContentAliases.PreviewOverlay;
    actionBarAlias = LazyContentAliases.ActionBar;
    actionBarData?: IActionBar;
    schema: any;
    @ViewChild('vcModelPageNav', { read: ViewContainerRef, static: true })
    viewContainerModelPageNav: ViewContainerRef;
    @ViewChild('vcFooter', { read: ViewContainerRef, static: true })
    viewContainerFooter: ViewContainerRef;
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly translateService: TranslateService,
        private readonly settingsService: SettingsService,
        private readonly metaService: MetaService,
        private readonly cd: ChangeDetectorRef,
        private readonly scrollService: ScrollService,
        private readonly bloomreachService: BloomreachService
    ) {
        this.bloomreachService.initBloomreach();
        this.scrollService.initScrollRestoration();
    }

    ngOnInit() {
        this._lazyLoadFooter();

        // JSON+LD
        this.settingsService
            .get()
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.actionBarData = settings.actionBar;
                const baseUrl = this.metaService.getBaseUrl();

                this.schema = {
                    '@context': 'https://schema.org',
                    '@type': 'Organization',
                    'url': baseUrl,
                    'logo': `${baseUrl}/assets/images/bag-logo.svg${getBuildIdQuery()}`,
                    'name': settings.companyName,
                };

                this.cd.markForCheck();
            });

        return this.translateService.use('platform');
    }

    private async _lazyLoadFooter() {
        this.viewContainerFooter.clear();
        const { FooterComponent } = await import('@features/layout/footer/footer.component');
        this.viewContainerFooter.createComponent(FooterComponent);
        this.cd.markForCheck();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
